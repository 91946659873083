.myStyle {
  background-image: url('./assets/images/blueprintBackground.jpg');
  height: 100vh;
  margin-top: -70px;
  font-size: 45px;
  background-size: cover;
  background-repeat: repeat-y;
  font-family: Arial;
  color: white;
  text-align: left;  /* Text of .myStyle will be aligned to the left */
}

.projectListContainer {
  /* Retained base styling without affecting all child elements. */
  font-size: 15px;
  font-family: Arial;
  color: white;
}

.projectListContainer > * {
  /* Use > to select direct children and avoid affecting nested elements unintentionally. */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  text-align: center; /* Ensuring child elements' text is centered. */
}

.project-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  margin-top: 10vh;
  margin-bottom: 10vh;
  font-size: 15px;
  font-family: Arial;
  color: white;
  text-align: center;  /* Text of .project-list will be centered */
}
