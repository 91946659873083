.project,
.project-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 

    border-radius: 12px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    height: 24vh;
    width: 12vw;
    margin-top: -50px;
    margin-left: 20px;
    font-size: 15px;
    font-family: Arial;
    color: white;
}

.project-image{
    height: 100px;
    width: auto;
    border-radius: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.project-title{
    font-size: 15px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
}
.project-description {
    width: 100%; 
    text-align: center; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    flex-direction: column;
    font-size: 12px;
    margin-bottom: 15px; 
}

.project-container:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    box-shadow: 0 5px 45px rgba(255, 255, 255, 0.55);
    transition: all 0.2s ease-in-out;
    transform: translateY(-5px);
}

