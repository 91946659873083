@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #2d2834; */
  background: radial-gradient(#27212e, #221530);
  color: #d9c9ee;
}

header {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  text-align: center;
}

header img {
  width: 7rem;
}

header h1 {
  margin: 0;
  font-size: 1.5rem;
}

header p {
  margin: 0;
}

#concepts {
  max-width: 50rem;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.concept {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  background: linear-gradient(#250358, #440c66);
  padding: 1.5rem;
  border-radius: 6px;
  box-shadow: 0 2px 20px rgba(234, 78, 240, 0.2);
  width: 33%;
  transition: all 0.2s
}

.concept:hover {
  transform: translateY(-2px);
  transition: transform 0.2s;
  box-shadow: 0 2px 30px rgba(234, 78, 240, 0.4);
}

.concept img {
  width: 6rem;
  height: 5rem;
  object-fit: contain;
}

.concept h2 {
  margin: 0;
  font-size: 1rem;
  text-transform: uppercase;
}

.concept p {
  margin: 0;
  text-align: center;
  font-size: 0.85rem;
}
.project,
.project-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 

    border-radius: 12px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    height: 24vh;
    width: 12vw;
    margin-top: -50px;
    margin-left: 20px;
    font-size: 15px;
    font-family: Arial;
    color: white;
}

.project-image{
    height: 100px;
    width: auto;
    border-radius: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.project-title{
    font-size: 15px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
}
.project-description {
    width: 100%; 
    text-align: center; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    flex-direction: column;
    font-size: 12px;
    margin-bottom: 15px; 
}

.project-container:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    box-shadow: 0 5px 45px rgba(255, 255, 255, 0.55);
    transition: all 0.2s ease-in-out;
    transform: translateY(-5px);
}


.card {
    border-radius: 12px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
}
.myStyle {
  background-image: url(/static/media/blueprintBackground.ac3246cc.jpg);
  height: 100vh;
  margin-top: -70px;
  font-size: 45px;
  background-size: cover;
  background-repeat: repeat-y;
  font-family: Arial;
  color: white;
  text-align: left;  /* Text of .myStyle will be aligned to the left */
}

.projectListContainer {
  /* Retained base styling without affecting all child elements. */
  font-size: 15px;
  font-family: Arial;
  color: white;
}

.projectListContainer > * {
  /* Use > to select direct children and avoid affecting nested elements unintentionally. */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  text-align: center; /* Ensuring child elements' text is centered. */
}

.project-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  margin-top: 10vh;
  margin-bottom: 10vh;
  font-size: 15px;
  font-family: Arial;
  color: white;
  text-align: center;  /* Text of .project-list will be centered */
}

